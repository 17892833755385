import React from 'react';
import { Link } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';

export const Navbar: React.VFC = () => {
	return (
		<nav className="text-secondary relative my-2 mx-8 flex flex-wrap justify-between items-end">
			<Link to="/">
				<StaticImage
					src="../assets/logo.png"
					alt="Logo"
					height={70}
					layout="fixed"
				/>
			</Link>
			<button
				className="w-8 h-8 bg-transparent flex justify-center items-center rounded sm:hidden hover:bg-gray-200"
				onClick={() =>
					document
						.getElementById('mobile-menu')
						?.classList.toggle('hidden')
				}
			>
				<svg
					className="w-6 h-6"
					fill="none"
					stroke="currentColor"
					viewBox="0 0 24 24"
					xmlns="http://www.w3.org/2000/svg"
				>
					<path
						strokeLinecap="round"
						strokeLinejoin="round"
						strokeWidth="2"
						d="M4 6h16M4 12h16M4 18h16"
					/>
				</svg>
			</button>
			<div
				className="hidden mt-4 sm:mt-0 w-full sm:w-auto sm:inline-block"
				id="mobile-menu"
			>
				<ul className="flex flex-col sm:items-end sm:flex-row sm:gap-8">
					<li>
						<Link
							to="/"
							onClick={() =>
								document
									.getElementById('mobile-menu')
									?.classList.toggle('hidden')
							}
							className="font-bold rounded block py-2 sm:py-0 hover:bg-gray-200 sm:hover:bg-transparent text-center border-b-2 sm:border-none"
						>
							Startseite
						</Link>
					</li>
					<li>
						<Link
							to="/ueber-mich"
							onClick={() =>
								document
									.getElementById('mobile-menu')
									?.classList.toggle('hidden')
							}
							className="font-bold rounded block py-2 sm:py-0 hover:bg-gray-200 sm:hover:bg-transparent text-center border-b-2 sm:border-none"
						>
							Über mich
						</Link>
					</li>
					<li>
						<Link
							to="/leistungen"
							onClick={() =>
								document
									.getElementById('mobile-menu')
									?.classList.toggle('hidden')
							}
							className="font-bold rounded block py-2 sm:py-0 hover:bg-gray-200 sm:hover:bg-transparent text-center border-b-2 sm:border-none"
						>
							Leistungen
						</Link>
					</li>
					<li>
						<Link
							to="/kontakt"
							onClick={() =>
								document
									.getElementById('mobile-menu')
									?.classList.toggle('hidden')
							}
							className="font-bold rounded block py-2 sm:py-0 hover:bg-gray-200 sm:hover:bg-transparent text-center"
						>
							Kontakt
						</Link>
					</li>
					<li className="hidden sm:block">
						<a
							href="tel:087196685640"
							className="text-secondary flex flex-col items-center gap-2 mb-2"
						>
							<div className="bg-primary h-6 w-6 rounded-full text-white shadow-xl flex justify-center items-center">
								<svg
									className="w-4 h-4"
									fill="none"
									stroke="currentColor"
									viewBox="0 0 24 24"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										strokeLinecap="round"
										strokeLinejoin="round"
										strokeWidth="2"
										d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z"
									></path>
								</svg>
							</div>
						</a>
						<a
							href="mailto:info@la-regionalbau.de"
							className="flex flex-col items-center gap-2"
						>
							<div className="bg-primary h-6 w-6 rounded-full text-white shadow-xl flex justify-center items-center">
								<svg
									className="w-4 h-4"
									fill="none"
									stroke="currentColor"
									viewBox="0 0 24 24"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										strokeLinecap="round"
										strokeLinejoin="round"
										strokeWidth="2"
										d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
									></path>
								</svg>
							</div>
						</a>
					</li>
				</ul>
			</div>
		</nav>
	);
};
