import React from 'react';
import { Navbar } from './Navbar';
import { Link } from 'gatsby';
import { Helmet } from 'react-helmet';
import '../styles.css';

export const Layout: React.FC = (props) => (
	<div className="h-screen">
		<Helmet htmlAttributes={{ lang: 'de' }}>
			<title>
				LA regional Bau - Der Meisterbetrieb für Ihre Bauprojekte
			</title>
			<meta
				name="description"
				content="LA regional Bau ist der Meisterbetrieb im Stadt- und Landkreis Landshut zur Verwirklichung Ihrer Bauprojekte!"
			/>
			<meta name="robots" content="index" />
			<meta charSet="utf-8" />
		</Helmet>
		<header className="shadow-md fixed top-0 left-0 right-0 bg-white z-20">
			<Navbar />
		</header>
		<main className="flex-1 p-5 pt-28 sm:p-10 sm:pt-28 min-h-full box-border max-w-3xl m-auto">
			{props.children}
		</main>
		<footer className="block w-full h-14 bg-secondary text-white">
			<div className=" flex justify-center gap-8">
				<Link to="/datenschutz">Datenschutz</Link>
				<Link to="/kontakt">Kontakt</Link>
				<Link to="/impressum">Impressum</Link>
			</div>
			<div className=" flex justify-center gap-8">
				<Link to="https://www.instagram.com/andreas.leyrer">
					<svg
						fill="currentColor"
						xmlns="http://www.w3.org/2000/svg"
						viewBox="0 0 24 24"
						width="24px"
						height="24px"
					>
						<path d="M16,3H8C5.243,3,3,5.243,3,8v8c0,2.757,2.243,5,5,5h8c2.757,0,5-2.243,5-5V8C21,5.243,18.757,3,16,3z M19,16c0,1.654-1.346,3-3,3H8c-1.654,0-3-1.346-3-3V8c0-1.654,1.346-3,3-3h8c1.654,0,3,1.346,3,3V16z" />
						<path d="M12 7c-2.757 0-5 2.243-5 5s2.243 5 5 5 5-2.243 5-5S14.757 7 12 7zM12 15c-1.654 0-3-1.346-3-3s1.346-3 3-3 3 1.346 3 3S13.654 15 12 15zM17 6A1 1 0 1 0 17 8 1 1 0 1 0 17 6z" />
					</svg>
				</Link>
				<Link to="https://de-de.facebook.com/people/Andreas-Leyrer/100080093802227/">
					<svg
						fill="currentColor"
						xmlns="http://www.w3.org/2000/svg"
						viewBox="0 0 30 30"
						width="24px"
						height="24px"
					>
						<path d="M12,27V15H8v-4h4V8.852C12,4.785,13.981,3,17.361,3c1.619,0,2.475,0.12,2.88,0.175V7h-2.305C16.501,7,16,7.757,16,9.291V11h4.205l-0.571,4H16v12H12z" />
					</svg>
				</Link>
			</div>
		</footer>
	</div>
);
